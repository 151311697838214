<template>
  <transition>
    <div>
      <div>
        <DxDataGrid
          :ref="dataGrid.refName"
          :id="dataGrid.id"
          key-expr="subjId"
          :allow-column-reordering="true"
          :data-source="dataGrid.dataSource"
          :show-column-headers="true"
          :show-borders="false"
          :column-auto-width="true"
          :show-row-lines="true"
          :show-column-lines="false"
          :no-data-text="this.$_msgContents('CMN_NO_DATA')"
          :selected-row-keys="dataGrid.selectedRowKeys"
          @cell-hover-changed="onCellHoverChanged"
          @row-click="onRowClick"
          @selection-changed="onSelectionChanged"
          height="450"
        >
          <DxScrolling mode="virtual"/>
          <DxSelection mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxHeaderFilter :visible="true" />

          <DxColumn caption="강의명" data-field="eduNm" alignment="left" :allow-header-filtering="false" :allow-sorting="true" />
          <DxColumn
            caption="진행방식"
            data-field="divisionCd"
            alignment="center"
            width="140"
            :allow-header-filtering="true"
            :allow-sorting="true"
            :calculate-sort-value="sortByDivisionCd"
          >
            <DxLookup :data-source="ewmCodes.eduEducationProcessDivision.values" value-expr="value" display-expr="label" />
          </DxColumn>
          <DxColumn
            caption="소요시간"
            data-field="eduTime"
            alignment="center"
            :allow-filtering="false"
            :allow-header-filtering="false"
            :allow-sorting="true"
            :calculate-display-value="calculateEduTime"
            width="140"
          >
          </DxColumn>
          <DxColumn
            caption="강의유형"
            data-field="typeCd"
            alignment="center"
            :allow-header-filtering="true"
            :allow-sorting="true"
            width="190"
            :calculate-sort-value="sortByTypeCd"
          >
            <DxLookup :data-source="codes.typeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
          </DxColumn>
          <DxColumn
            caption="강사 [ID]"
            data-field="teachers"
            alignment="center"
            :allow-filtering="false"
            :allow-header-filtering="false"
            :allow-sorting="false"
            width="170"
            :cellTemplate="teacherTemplate"
          >
          </DxColumn>
          <DxColumn
            caption="첨부파일"
            data-field="fileGroupId"
            alignment="center"
            :allow-filtering="false"
            :allow-header-filtering="false"
            :allow-sorting="false"
            width="100"
            :cell-template="downloadCellTemplate"
          >
          </DxColumn>
        </DxDataGrid>
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import {
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxGroupPanel,
    DxHeaderFilter,
    DxLookup,
    DxScrolling,
    DxSelection,
  } from 'devextreme-vue/data-grid';
  import { DxPopover } from 'devextreme-vue/popover';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';

  export default {
    components: {
      DxGroupPanel,
      DxButton,
      DxDataGrid,
      DxColumn,
      DxLookup,
      DxScrolling,
      DxSelection,
      DxFilterRow,
      DxHeaderFilter,
      DxPopover,
    },
    props: {
      contentData: {
        default: () => {
          return [];
        },
        type: Array,
      },
    },
    data() {
      return {
        defaultVisible: false,
        popover_caption: '',
        popover_description: '',
        hoverTarget: null,
        codes: {
          typeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        selectedList: [],
        dataGrid: {
          id: 'educationList',
          refName: 'educationList',
          dataSource: [],
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        isSelectionStopped: true,
      };
    },
    computed: {
      ewmCodes() {
        return this.$_enums.ewm;
      },
    },
    methods: {
      /** @description : 소트설정 */
      sortByDivisionCd(rowData) {
        return this.$_enums.ewm.eduEducationProcessDivision.values.find(e => rowData.divisionCd === e.value).label;
      },
      sortByTypeCd(rowData) {
        return this.codes.typeCd.dataSource.find(e => rowData.typeCd === e.codeId).codeNm;
      },
      /** @description : 강사컬럼 마우스 hover 이벤트 */
      onCellHoverChanged(e) {
        const event = e.event;
        if (event.buttons === 1) {
          if (this.isSelectionStopped) {
            this.isSelectionStopped = false;
            this.selectedRange = {};
          }

          if (this.selectedRange.startRowIndex === undefined) {
            this.selectedRange.startRowIndex = e.rowIndex;
          }

          if (!this.selectedRange.startColumnIndex) {
            this.selectedRange.startColumnIndex = e.columnIndex;
          }

          this.selectedRange.endRowIndex = e.rowIndex;
          this.selectedRange.endColumnIndex = e.columnIndex;

          let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
          let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

          let indexes = [];
          for (let i = start; i <= end; i++) {
            indexes.push(i);
          }
          e.component.selectRowsByIndexes(indexes);
        } else {
          this.isSelectionStopped = true;
        }
      },
      onRowClick(e) {
        let keys = e.component.getSelectedRowKeys();
        let index = keys.indexOf(e.key);

        if (index > -1) {
          keys.splice(index, 1);
        } else {
          keys.push(e.key);
        }

        e.component.selectRows(keys);
      },
      calculateEduTime(rowData) {
        const pad = num => num.toString().padStart(2, '0');
        return `${pad(Math.floor(rowData.eduTime / 60))}:${pad(rowData.eduTime % 60)}`;
      },
      onSelectionChanged({ selectedRowsData }) {
        this.$emit('input', selectedRowsData);
      },
      /**
       * 첨부파일 다운로드
       *
       * @param fileGroupId
       * @return {Promise<void>}
       */
      async downloadFile(fileGroupId) {
        const fileList = await this.$_getListAttachFile(fileGroupId);

        if (!fileList || fileList.length === 0) {
          this.$_Msg('다운로드 할 파일이 없습니다.');
          return;
        }

        fileList.forEach(fileInfo => {
          this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
        });
      },
      /** @description: 데이터 조회 메서드 */
      async selectDataList() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'subjId',
          async load() {
            const payload = {
              actionname: 'EWM_EDUCATION_SUBJECT_LIST',
              data: {
                isAll: 'Y',
              },
              useErrorPopup: true,
            };

            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            }
            return rtnData;
          },
        });
      },
      /** @description : 코드 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_type');
      },
      /**
       * 강사 컬럼 템플릿
       *
       * @param container
       * @param options
       */
      teacherTemplate(container, options) {
        if (!options.value) return;
        let innerText = '';
        let popoverText = '';
        let spanTag = document.createElement('span');

        if (options.value.length === 1) {
          innerText = `${options.value[0].teacherName}[${options.value[0].teacherId}]`;
          popoverText = `<li>${options.value[0].teacherName}[${options.value[0].teacherId}]</li>`;
        } else if (options.value.length > 1) {
          innerText = `${options.value.length}명`;
          options.value.forEach(val => {
            popoverText += `<li>${val.teacherName}[${val.teacherId}]</li>`;
          });
        }
        spanTag.innerText = innerText;

        const dxPopover = new DxPopover({
          propsData: {
            width: 'auto',
            showEvent: 'mouseenter',
            hideEvent: 'mouseleave',
            target: container,
            position: 'top',
            contentTemplate: `<ul>${popoverText}</ul>`,
          },
        });

        dxPopover.$mount();
        container.append(spanTag);
        container.append(dxPopover.$el);
      },
      downloadCellTemplate(container, options) {
        if (!options.value) return;

        const button = new DxButton({
          propsData: {
            elementAttr: { class: 'download' },
            value: options.value,
            onClick: () => {
              this.downloadFile(options.value);
            },
          },
        });
        button.$mount();
        container.append(button.$el);
      },
    },
    mounted() {
      this.selectedList = this.contentData;
      this.initCodeMap().then(() => {
        this.codes.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
      });
      this.selectDataList();

      if (this.selectedList.length > 0) {
        this.dataGrid.selectedRowKeys = this.selectedList;
      } else {
        this.selectedList = [];
      }
    },
  };
</script>
